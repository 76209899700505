/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@import "assets/fonts/feather/iconfont.scss";

@import "./theme/theme";

@import "./assets/css/moyasar.css";

.clearfix {
    display: table;
    content: "";
    clear: both;
}

.md {
    font-family: "Montserrat", Helvetica, Arial, serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #2c2c2c;
}
table,
th,
td {
    // border: 1px solid black;
    border-collapse: collapse;
}
th,
td {
    padding: 15px;
}

@media only screen and (max-width: 600px) {
    .split-pane-md.split-pane-visible > .split-pane-side {
        max-width: 260px !important;
        width: 260px !important;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 5%) !important;
    }

    .md-drppicker {
        position: sticky !important;
        top: 10px !important;
    }
    [hidden],
    .hidden-md {
        display: block !important;
    }
    .hidden-xs,
    .hidden {
        display: none !important;
    }
    ion-card[fullscreen] {
        width: 100% !important;
        margin: 0px !important;
    }

    ion-popover.profile-popover {
        div.popover-content {
            position: absolute !important;
            top: 65px !important;
            transform-origin: var(--end) top !important;
            right: 0px !important;
            width: 200px !important;
            text-align: center !important;
        }
    }

    [dir="ltr"] ion-popover.profile-popover {
        div.popover-content {
            left: 42vw !important;
        }
    }

    [dir="rtl"] ion-popover.profile-popover {
        div.popover-content {
            right: 42vw !important;
        }
    }
}

@media only screen and (min-width: 600px) {
    .box {
        width: 100%;
    }

    .md-drppicker {
        z-index: 9999 !important;
        position: fixed !important;
        width: 500px !important;
        height: 300px !important;
    }

    [dir="ltr"] .md-drppicker {
        right: 0px !important;
        transform-origin: right top !important;

        // transform-origin: left top !important;
        left: calc(100% - 550px) !important;
    }

    [dir="rtl"] .md-drppicker {
        left: 0px !important;
        transform-origin: left top !important;

        // transform-origin: left top !important;
        right: calc(100% - 550px) !important;
    }

    body.rtl {
        ion-popover.profile-popover {
            div.popover-content {
                transform-origin: left top !important;
                left: 0px !important;
                width: 200px !important;
                right: calc(100% - 230px) !important;
            }
        }
    }
    body.ltr {
        ion-popover.profile-popover {
            div.popover-content {
                transform-origin: right top !important;
                right: 0px !important;
                width: 200px !important;
                left: calc(100% - 230px) !important;
            }
        }
    }
    ion-popover.profile-popover {
        div.popover-content {
            position: absolute !important;
            top: 65px !important;
            text-align: right !important;
        }
    }
    ion-header {
        --border-style: none;
    }
    [hidden],
    .hidden-xs {
        display: block !important;
    }
    .hidden-md,
    .hidden {
        display: none !important;
    }
    .toolbar-md {
        height: 50px;
    }
    button[menuToggle] {
        margin-left: 16px;
        margin-right: 16px;
    }
    .split-pane-md.split-pane-visible > .split-pane-side {
        max-width: 260px !important;
        --max-width: 260px !important;
        width: 260px !important;
        --width: 260px !important;
        min-width: 260px !important;
        --min-width: 260px !important;
    }
    ion-menu {
        border: 0px !important;
    }
}

@media only screen and (min-width: 1200px) {
    .box {
        width: calc(85% - 20px) !important;
        margin: 0px auto !important;
    }
}
.no-shadow {
    box-shadow: 0px !important;
}
.no-hover {
    --background-hover: none !important;
}

ion-header ion-toolbar ion-title {
    text-transform: capitalize !important;
}
ion-button span {
    text-transform: capitalize !important;
}

.ngx-datatable .datatable-body .datatable-row-wrapper {
    margin-bottom: 10px !important;
}
ngx-datatable.invoice {
    datatable-header {
        * {
            color: #fff !important;
        }
        background-color: #333 !important;
    }
}
.ngx-datatable.bootstrap .datatable-footer {
    margin-top: 30px !important;
}
.ngx-datatable {
    datatable-header,
    datatable-body {
        width: 100vw !important;
        .datatable-header-cell {
            border-bottom: 0px solid transparent !important;
        }
    }
    .empty-row {
        text-align: center !important;
        background-color: #fff !important;
        padding: 1.25rem !important;
        width: 100vw !important;
    }
    .datatable-footer {
        display: block !important;
        //height: 30px !important;
        background: #eee !important;
        width: auto !important;
        max-width: 350px !important;
        border-radius: 30px;
        margin: 50px auto;
        color: var(--ion-color-medium) !important;
        ul > :first-child,
        ul > :last-child {
            display: none !important;
        }
        li {
            a:hover,
            a:focus {
                border-radius: 10px !important;
                background-color: transparent !important;
            }
        }
        li.pages {
            margin-top: 2px !important;
            a:hover,
            a:focus {
                color: #fff !important;
                background: var(--ion-color-primary) !important;
            }
        }
        * {
            color: var(--ion-color-medium) !important;
        }
        //ul.pager {
        //    margin: 0px auto !important;
        //    min-width: 250px !important;
        //    width: auto !important;
        //    display: block !important;
        //}
        .page-count {
            display: none !important;
        }
        .active a {
            border-radius: 10px !important;
            color: #fff !important;
            background: var(--ion-color-primary) !important;
        }
    }
}
.ngx-datatable.bootstrap .datatable-body {
    background-color: transparent !important;
    * {
        color: #333;
        font-weight: 500;
    }
    ion-button span {
        color: #fff !important;
    }
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
    border: 0px !important;
    background-color: #ffffff !important;
    box-shadow: rgb(0 0 0 / 5%) 0 4px 20px 0 !important;
    cursor: pointer !important;
    -webkit-transition: all 0.3s ease !important;
    transition: all 0.3s ease !important;
    border-radius: 0.5rem !important;
    padding: 0px !important;
    font-size: 14px !important;
    line-height: 20px !important;
}
ion-item {
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: transparent;
    --background-focused: transparent;
    --background-focused-opacity: transparent;
    --background-hover: transparent;
    --background-hover-opacity: transparent;
}

#customer-chart .apexcharts-tooltip-marker {
    background-color: var(--ion-color-primary) !important;
    background: var(--ion-color-primary) !important;
}

#revenue-chart .apexcharts-tooltip-marker {
    background-color: #28c76f !important;
    background: #28c76f !important;
}

#sold-chart .apexcharts-tooltip-marker {
    background-color: #ea5455 !important;
    background: #ea5455 !important;
}
#order-chart .apexcharts-tooltip-marker {
    background-color: #ff9f43 !important;
    background: #ff9f43 !important;
}
.apexcharts-tooltip-marker {
    //.apexcharts-tooltip-text-label
    display: none !important;
}

//ion-input {
//    border-radius: 5px !important;
//    border: 1px solid #e0e0e0 !important;
//    // margin-bottom: 20px;
//    margin-top: 0px;
//    height: 35px !important;
//    line-height: 17px !important;
//    font-size: 13px !important;
//    font-weight: 500 !important;
//    * {
//        --background: none;
//        background: none;
//        --color: #4e5154 !important;
//        color: #4e5154 !important;
//    }
//}
.fileuploader {
    width: 100%;
    padding: 0px !important;
    margin: 0px;
    background: none !important;
    border-radius: 3px !important;
}

.fileuploader-input-button {
    padding: 0 !important;
    height: 35px !important;
    border-radius: 5px !important;
    width: 120px !important;
    font-weight: 500 !important;
    box-shadow: none !important;
    --box-shadow: none !important;
}

.fileuploader-input-caption {
    * {
        font-size: 12px !important;
    }
    margin: 0px 5px !important;
    padding: 9px 16px !important;
    width: 100% !important;
    border-radius: 5px !important;
}
select {
    border: 1px solid #e0e0e0 !important;
    width: 100%;
    padding-top: 7px;
    padding-bottom: 6px;
    line-height: 17px !important;
    font-size: 13px !important;
    // margin-bottom: 10px;
    background-color: transparent !important;
    margin-top: 0px;
    -webkit-appearance: none !important;
}
select.minimal {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #e0e0e0, #e0e0e0);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
}
[dir="rtl"] {
    select.minimal {
        background-position: calc(100% - 151px) calc(1em + 2px), calc(100% - 146px) calc(1em + 2px),
            calc(100% - 10em) 0.5em;
    }
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

.validation-errors {
    text-align: var(--start) !important;
    font-size: 10px !important;
    color: red !important;
}

.txt-error {
    color: red !important;
}

.iti {
    display: block !important;
}

.iti .dropdown-menu.country-dropdown {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-color: #c7cace;
    margin-top: -1px;
}

.iti .iti__country-list {
    box-shadow: none;
    font-size: 14px;
    // width: 4px;
    max-height: 170px;
}

.iti__flag-container.open + input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.iti .search-container input {
    font-size: 14px;
    border-color: #c7cace;
    border-radius: 0;
    padding: 5px 10px;
}

.iti .search-container input:focus {
    outline: none;
}

ngx-intl-tel-input input {
    min-height: 52px;
    margin: 9px 0 18px 0;
    border: solid 1px #9d9d9d;
    background-color: #ffffff;
    width: 100% !important;
    padding: 10px;
    border-radius: 4px;
    line-height: 17px !important;
    font-weight: 500 !important;
    font-size: 13px !important;
}

.customer-phone-number {

    app-tel-input {
        margin-top: 15px;
        display: block;
    }

    ion-button {
        float: var(--start);
        margin-top: 9px;
        top: 0 !important;
    }

    input {
        min-height: 52px;
        border: solid 1px #e0e0e0;
        border-radius: 2px;
        display: inline-block !important;
    }
}


.tel-input {
    --min-width: 320px;
    --width: 320px;    
}

.register_phone_field ion-input {
    border: solid 1px #9d9d9d !important;
}

.phone_field input1 {
    margin: 9px 0 18px 0;
    border: solid 1px #e0e0e0;
    width: 100% !important;
    padding: 0px;
    border-radius: 2px;
    font-weight: normal !important;
    font-size: 16px !important;
    color: #000;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
    border: 1px solid #c0392b;
}

ngx-intl-tel-input input:focus {
    outline: none !important;
    border-color: #3498db;
    box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
    background-color: #e5eaf1;
}

[dir="rtl"] ngx-intl-tel-input {
    .iti--allow-dropdown .iti__flag-container,
    .iti--separate-dial-code .iti__flag-container {
        right: 0;
        left: auto;
    }

    //.iti--allow-dropdown input,
    //.iti--allow-dropdown input[type=text],
    .iti--allow-dropdown input[type=tel],
    //.iti--separate-dial-code input,
    //.iti--separate-dial-code input[type=text],
    .iti--separate-dial-code input[type=tel] {
        padding-left: 6px;
        padding-right: 52px;
        margin-right: 0;
    }

    .dropdown-menu {
        float: var(--start);
        transform: translateX(calc(-100% + 47px)) translateY(0px) !important; //translateX(calc(-100% + 81px)) translateY(0px);
    }

    .iti__country-list {
        text-align: right;
    }

    .iti__flag-box,
    .iti__country-name {
        margin-left: 6px;
        margin-right: 0;
    }

    .iti__country {
        direction: ltr;
    }
}

//.searchbar-search-icon.sc-ion-searchbar-md {
//    top: 10px;
//    width: 18px;
//    height: 18px;
//}
//
//.searchbar-input.sc-ion-searchbar-md {
//    padding-inline-start: 38px !important;
//    padding-inline-end: 0;
//    padding-top: 3px !important;
//    padding-bottom: 2px;
//    height: auto;
//    font-size: 14px !important;
//}

.content-header {
    padding: 0 10px;
    margin-bottom: 21px;
}

.content-header-title {
    font-weight: 500;
    color: #636363;
    float: var(--start);
    margin-top: 0;
}

a {
    color: var(--ion-color-primary);
    text-decoration: none;
    background-color: transparent;
}

.breadcrumbs-top .breadcrumb {
    margin: 0;
}

.breadcrumb {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #ededed;
    border-radius: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    color: #b8c2cc;
    content: "/";
}
.breadcrumb > li + li::before {
    padding-right: 0.6rem;
    padding-left: 0.6rem;
}

/*.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
    content: '\e847';
    font-family: 'feather';
    color: #626262;
}*/

.breadcrumb {
    font-size: 14px;
    font-family: "Montserrat", Helvetica, Arial, serif;
    background-color: transparent;
    border-radius: 0;
    list-style: none;
}

ion-label {
    white-space: normal !important;
}

ion-card {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    //border: 1px solid rgba(34, 41, 47, 0.125);
    //margin-bottom: 2.2rem;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    ion-col {
        padding: 14px;

        h2 {
            color: #626262;
        }
    }

    .table {
        width: 100%;
        margin-bottom: 1rem;
        color: #626262;

        th {
            font-size: 0.85rem;
        }

        th,
        td {
            padding: 1rem;
            vertical-align: middle;
        }
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #f8f8f8;
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #626262;
}

@media print {
    ion-card {
        box-shadow: none;
    }

    ion-menu,
    app-header,
    .non-print {
        display: none;
    }
    .printable {
        display: block;
    }
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
    margin: 0 auto !important;
    text-align: center !important;
}
.next-page,
.prev-page {
    a {
        position: relative;
        i {
            position: absolute;
            top: 2px;
        }
    }
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
    margin: 0;
}
.ck.ck-content.ck-editor__editable {
    min-height: 128px;
}

.upgrade-popover .popover-viewport,
.upgrade-popover .popover-content {
    overflow-x: hidden;
}

.upgrade-popover {
    --width: 335px;
    --height: auto;
    --min-height: 300px;
    --max-height: 410px;
}

.alert-new-order {
    --height: auto;
    --width: 335px;
    --min-height: 390px;
    //--max-height: 410px;
    --border-radius: 20px;
    --backdrop-opacity: 0.75;
    --background: #fff;

    .ion-page {
        padding: 0 20px 20px 20px;
    }

    ion-backdrop {
        --backdrop-opacity: 0.75;
    }
}

.alert-new-order .modal-wrapper {
    z-index: 20001;
    padding: 40px 20px 30px;
}

.upgrade-popover .popover-content,
.upgrade-popover .popover-viewport {
    z-index: 20001;
    padding: 40px 20px 30px;
    border-radius: 20px;
    background-color: #fff;
    --backdrop-opacity: 0.75;

    .ion-page {
        padding: 0 20px 20px 20px;
    }

    ion-backdrop {
        --backdrop-opacity: 0.75;
    }
}

.password_change_icon {
    position: absolute;
    bottom: 0;
    z-index: 111;
    opacity: 0.7;
    background: transparent;
    top: -4px;

    ion-icon {
        font-size: 22px;
        color: #000;
    }
}

.password_change_icon:focus {
    outline: none;
}

[dir="ltr"] {
    .breadcrumb-item + .breadcrumb-item::before {
        padding-right: 0.5rem;
    }

    .ngx-datatable .empty-row {
        margin-left: -12% !important;
    }

    select {
        padding-left: 8px;
        padding-right: 10px !important;
    }

    .breadcrumb {
        border-left: 1px solid #d6dce1;
    }

    .prev-page {
        a {
            i {
                left: -21px;
            }
        }
    }

    .searchbar-input.sc-ion-searchbar-md {
        background-position: left 8px center;
    }

    .searchbar-search-icon.sc-ion-searchbar-md {
        left: 6px;
    }

    .iti .iti__country-list {
        margin-left: 0;
    }

    .password_change_icon {
        right: 0;
    }

    .validation-errors {
        margin-left: 15px !important;
    }
}

[dir="rtl"] {
    .breadcrumb-item + .breadcrumb-item::before {
        padding-left: 0.5rem;
    }
    .ngx-datatable .empty-row {
        margin-right: -12% !important;
    }

    select {
        padding-right: 8px;
        padding-left: 10px !important;
    }

    .breadcrumb {
        border-right: 1px solid #d6dce1;
    }

    .prev-page {
        a {
            i {
                right: -21px;
            }
        }
    }

    .searchbar-input.sc-ion-searchbar-md {
        background-position: right 8px center;
    }

    .searchbar-search-icon.sc-ion-searchbar-md {
        right: 6px;
    }

    .iti .iti__country-list {
        margin-right: 0;
    }

    .password_change_icon {
        left: 0;
    }

    .validation-errors {
        margin-right: 15px !important;
    }
}

.password_field {
    position: relative;

    ion-input {
        --padding-end: 60px;
    }
}

.content-header-left:after {
    content: "";
    display: table;
    clear: both;
}

[dir="ltr"] {
    .content-header-title {
        margin-right: 1rem;
    }

    .ml-5 {
        margin-left: 5px;
    }

    .ml-10 {
        margin-left: 10px;
    }

    .pl-10 {
        padding-left: 10px;
    }
}

[dir="rtl"] {
    .content-header-title {
        margin-left: 1rem;
    }

    .ml-5 {
        margin-right: 5px;
    }

    .ml-10 {
        margin-right: 10px;
    }

    .pl-10 {
        padding-right: 10px;
    }

    .menu-link {
        transform: rotate(180deg);
    }

    .ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell,
    .ck.ck-editor__editable_inline,
    .ck-content,
    .ck-editor,
    .ql-editor {
        direction: rtl;
        text-align: right !important;
    }
}

.clear-header ion-toolbar {
    --border-width: 0 !important;
}

.clear-header:after {
    display: none;
}

ion-header.add-border:after {
    display: inline;
}

.input {
    min-height: 52px;
    margin: 9px 1px 18px 0 !important;
    border-radius: 2px;
    border: solid 1px #9d9d9d;
    background-color: #ffffff;
    --padding-start: 11px;
}

ion-label {
    font-family: NunitoSans;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

ion-select,
ion-input:not([type="checkbox"]),
ion-textarea,
select {
    min-height: 52px;
    margin: 9px 0 18px 0;
    border-radius: 2px !important;
    border: solid 1px #e0e0e0;
    background-color: #ffffff;
    --padding-start: 11px;
}

ion-input[type="checkbox"] {
    border-radius: 2px !important;
    border: solid 1px #e0e0e0;
    background-color: #ffffff;
    width: 33px;
    height: 33px;
    display: inline-block;
}

.ion-invalid,
.have-errors {
    ion-select,
    ion-input:not([type="checkbox"]),
    select {
        border: solid 1px var(--ion-color-warning);
    }
}

ion-card select {
    min-height: auto;
    margin: auto;
    border-radius: initial;
    --padding-start: initial;
}

ion-item {
    --full-highlight-height: 0;
}

[dir="ltr"] {
    .chart-voucher {
        padding: 18px 11px 0 10px;
        box-shadow: 0 1px 4px 0 rgb(0 0 0 / 25%);
    }

    .card-variant .btn-remove,
    .card-option .btn-remove {
        right: 0;
        left: auto !important;
    }
}

[dir="rtl"] {
    .card-variant .btn-remove,
    .card-option .btn-remove {
        right: auto !important;
        left: 0px;
    }

    .chart-voucher {
        padding: 18px 10px 0 11px;
        box-shadow: 0 0px 4px 1px rgb(0 0 0 / 25%);
    }
    .apexcharts-toolbar {
        position: absolute;
        right: auto !important;
        left: 10px !important;
    }

    ion-icon[name="call"],
    ion-icon[name="call-outline"] {
        transform: rotateZ(270deg);
    }
}

@media only screen and (min-width: 767px) {
    ion-header {
        border-bottom: 1px solid #e0e0e0 !important;
    }
}

@media only screen and (min-width: 980px) {
    /*
  todo: hide in desktop?
  ion-back-button{
    display: none!important;
  }*/
    .main-header {
        display: inline !important;
    }
}

@media only screen and (max-width: 980px) {
    ion-back-button {
        display: inline !important;
    }

    /*.main-header {
    display: none!important;
  }*/
}

.popup-modal {
    text-align: revert;
    justify-content: flex-end;
    --height: 100%;

    .modal-wrapper {
        height: 100%;
    }
}

.country-custom-modal {
    .popover-content {
        width: 627px;
    }
}

.mobile-only {
    display: none;
}

@media only screen and (max-width: 991px) {
    .mobile-only {
        display: inherit;
    }

    .desktop-only {
        display: none !important;
    }
}

ion-back-button {
    color: var(--ion-color-primary) !important;
}
.ltr {
    direction: ltr !important;
    display: inline-block !important;
}

ion-header ion-buttons[slot="end"] {
    ion-button {
        flex-grow: 0;
        font-family: NunitoSans;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--ion-color-primary);
        text-transform: none;
    }
}

.modal-calender ion-button {
    --color: #fff !important;
    color: #fff !important;
}

.select-popover {
    //--width: 95%;
    --max-width: 660px;
    //direction: ltr;
}

[dir="rtl"] .clock-face__container {
    margin-left: 94px !important;
}

.timepicker__header,
.clock-face__number > span.active,
.clock-face__clock-hand {
    background-color: var(--ion-color-primary) !important ;
}

.clock-face__clock-hand_minute::before {
    background-color: var(--ion-color-primary) !important ;
    border-color: var(--ion-color-primary) !important;
}

.modal-delivery-zone-tax {
    --backdrop-opacity: 0.75;

    .modal-wrapper {
        width: 375px;
        height: 285px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 30px;
        border-radius: 30px;
        background-color: #fff;
    }

    .drager {
        display: none;
    }
}

.range-knob-handle,
.range-knob {
    border: solid 4px #fff;
    background-color: #27ae60;
}

@media only screen and (max-width: 768px) {
    .modal-delivery-zone-tax {
        .modal-wrapper {
            position: fixed;
            width: 100%;
            bottom: 0;
            border-radius: 30px 30px 0 0;
        }

        .drager {
            display: block !important;
        }
    }
}


@media only screen and (min-width: 768px) {
    .country-modal, .alert-new-order {
        ion-backdrop {
            --backdrop-opacity: 0.75;
        }
        .modal-wrapper {
            z-index: 20001;
            border-radius: 10px;
        }
    }
}



@media only screen and (min-width: 768px) {
    .short-modal {
        ion-backdrop {
            --backdrop-opacity: 0.75;
        }
        .modal-wrapper {
            z-index: 20001;
            --width: 375px;
            min-height: 375px;
            max-height: 518px;
            margin: auto;
            --backdrop-opacity: 0.75;
            border-radius: 10px;
        }
    }
}

.timepicker__header,
.clock-face__number > span.active,
.clock-face__clock-hand {
    background-color: var(--ion-color-primary) !important ;
}

.clock-face__clock-hand_minute::before {
    background-color: var(--ion-color-primary) !important ;
    border-color: var(--ion-color-primary) !important;
}

.btn-status-0 {
    //draft
    --background: #bdbdbd !important;
}

.btn-status-1 {
    //pending
    --background: #f79b5a !important;
}

.btn-status-2 {
    //STATUS_BEING_PREPARED
    --background: #56ccf2 !important;
}

.btn-status-3 {
    //STATUS_OUT_FOR_DELIVERY
    --background: #2f80ed !important;
}

.btn-status-4 {
    //STATUS_COMPLETE
    --background: #219653 !important;
}

.btn-status-5 {
    // STATUS_CANCELED
    --background: #eb5757 !important;
}

.btn-status-6 {
    //STATUS_PARTIALLY_REFUNDED
    --background: #bb6bd9 !important;
}

.btn-status-7 {
    //STATUS_REFUNDED
    --background: #9b51e0 !important;
}

.btn-status-9 {
    //STATUS_ABANDONED_CHECKOUT
    --background: #eb5757 !important ;
}

.btn-status-10 {
    // Accepted
    --background: #6fcf97 !important;
}

app-delivery-zone-tax {
    border-radius: 30px;
    display: block;
}

ion-range::part(pin) {
    display: none !important;
}

[dir="rtl"] .datetime-text {
    direction: rtl !important;
}

@media only screen and (min-width: 768px) {
  .modal-lg  {
    --width: 720px;
  }
}

.required-field {
  color: red;
  padding: 3px;
}
.skip-step-toast {
  --width: 24%;
  --start: 1;
  --border-width: 37px;
  font-family: NunitoSans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333;
}

.mobile-only .static-btn {
    bottom: 8px !important;
}

apple-pay-button {
  --apple-pay-button-width: 140px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 5px;
  --apple-pay-button-padding: 5px 0px;
}
.guidance-modal {
  --border-radius: 20px;
  --width: 480px;
  --height: 626px;

  .ion-page {
    background: linear-gradient(180deg, #F6F5FF 0%, rgba(246, 245, 255, 0) 100%);
  }
}

ion-modal:not(.popup-modal):not(.overlay-hidden) {
    --backdrop-opacity: 0.3 !important;
}

ion-content {
    --padding-bottom: 120px;
}

.translated-ltr,
.translated-rtl {
    /*.static-btn {
        bottom: 50px !important;
    }

    ion-content {
        padding-bottom: 40px;
    }*/

  
    body {
        top: 0px !important;
    }

    ion-app ion-menu, 
    ion-app .ion-page:first-child {
        top: 40px;
    }

    .ion-modal .ion-page {
        top: 0px;
    }
}
 
.pull-left  {
    float: var(--start);
}

.pull-right  {
    float: var(--end);
}

[dir=rtl] {  
    ion-label.text {
      transform-origin: right top !important;
      // transform: translateY(50%) scale(0.75); 
    }
}

.grecaptcha-badge { 
    visibility: hidden !important;
}

ion-popover .popover-viewport {
    display: contents;
}


.order-phone-number {

    app-tel-input {
        margin-top: 15px;
        display: block;
    }

    ion-button {
        float: var(--start);
        margin-top: 9px;
        top: 0 !important;
        border-radius: 0 !important;
        --border-radius: 0 !important;
    }

    ion-input {
        padding: 5px 16px !important;
        min-height: 52px;
        border: solid 1px #e0e0e0 !important; 
        margin: 0 !important;
        display: inline-block !important;
        border-radius: 0 !important;
        --border-radius: 0 !important;

    }
}

ion-header {

.btn-close {
    padding: 0 !important;
    border-radius: 100%;
    box-shadow: none;
    border: none;
    background: #fff;//F5F5F7
    width: 40px;
    height: 40px;
  
    ion-icon {
        fill: #7D7D8D;
        color: #7D7D8D;
        font-size: 25px;
        font-weight: bold;
        position: relative;
        top: 0;
    }
  }
}