// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../assets/fonts/Nunito/NunitoSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../assets/fonts/Nunito/NunitoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../assets/fonts/Nunito/NunitoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/*@font-face {
	font-family: 'Montserrat';
	src: url('../assets/fonts/Montserrat-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}*/

/* Feather icons 
@font-face {
  font-family: feather;
  src: url(../assets/fonts/feather/fonts/feather.eot?t=1525787366991);
  src: url(../assets/fonts/feather/fonts/feather.eot?t=1525787366991#iefix) format('embedded-opentype'),
  url(../assets/fonts/feather/fonts/feather.woff?t=1525787366991) format('woff'),
  url(../assets/fonts/feather/fonts/feather.ttf?t=1525787366991) format('truetype'),
  url(../assets/fonts/feather/fonts/feather.svg?t=1525787366991#feather) format('svg')
}

.feather {
  font-family: feather!important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
*/ 

/** Ionic CSS Variables **/
:root {
  
  /** primary **/

  --ion-color-primary: #2B546A;
  --ion-color-primary-rgb: 43,84,106;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #264a5d;
  --ion-color-primary-tint: #406579;

  /** secondary **/
  --ion-color-secondary: #112131;
  --ion-color-secondary-rgb: 17,33,49;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #0f1d2b;
  --ion-color-secondary-tint: #293746;

  /** tertiary **/
  --ion-color-tertiary: #E4C7AB;
  --ion-color-tertiary-rgb: 228,199,171;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #c9af96;
  --ion-color-tertiary-tint: #e7cdb3;

  /** success **/
  --ion-color-success: #27AE60;
  --ion-color-success-rgb: 39,174,96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #229954;
  --ion-color-success-tint: #3db670;

  /** warning **/
  --ion-color-warning: #F2C94C;
  --ion-color-warning-rgb: 242,201,76;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #d5b143;
  --ion-color-warning-tint: #f3ce5e;

  /** danger **/
  --ion-color-danger: #E16563;
  --ion-color-danger-rgb: 225,101,99;
  --ion-color-new-contrast: #ffffff;
  --ion-color-new-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #c65957;
  --ion-color-danger-tint: #e47473;


  /** dark **/
  --ion-color-dark: #333333;
  --ion-color-dark-rgb: 51,51,51;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #2d2d2d;
  --ion-color-dark-tint: #474747;


  /** medium **/
  --ion-color-medium: #828282;
  --ion-color-medium-rgb: 130,130,130;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #727272;
  --ion-color-medium-tint: #8f8f8f;


  /** light **/
  --ion-color-light: #f8f8f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f4f5f8;

  /** light **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 244, 245, 248;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #ffffff;
  --ion-color-white-tint: #ffffff;
}

[dir="ltr"]  {
  --start: left; 
  --end: right;
}

[dir="rtl"] {
  --start: right; 
  --end: left;
}