

hr, hr * {
    background: #e0e0e0 !important;
    // height: 0.5px !important;
}
.visible-md {
    display: none !important;
}

/*body {
    -webkit-user-select: none !important;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-touch-callout: none !important;
}*/

body, html {
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.h-100 {
    height: 100vh !important;
}
.text-capitalize{
    text-transform: capitalize !important;
}
.fs-12 {
    font-size: 12px !important;
}
.fs-14 {
    font-size: 14px !important;
}
.fs-16 {
    font-size: 16px !important;
}
.fs-18 {
    font-size: 18px !important;
}
.fs-20 {
    font-size: 20px !important;
}
.fs-22 {
    font-size: 22px !important;
}
.bold {
    font-weight: 500 !important;
}
.b-b {
    border-bottom: 1px solid --ion-color-light !important;
}

.title-md {
    padding-left: -10px !important;
    padding-right: -10px !important;
}

.no-shadow {
    box-shadow: none !important;
    --box-shadow: none !important;
}
.text-capitalize{
    text-transform: capitalize !important;
}
.hidden {
    display: none !important;
}
.bold
{
    font-weight: 600 !important;
}
.fs-18
{
    font-size: 18px !important;
}
.fs-40
{
    font-size: 40px !important;
}
.fs-30
{
    font-size: 30px !important;
}
.fs-20
{
    font-size: 20px !important;
}
.fs-25
{
    font-size: 25px !important;
}
.fs-24
{
    font-size: 24px !important;
}
.fs-12
{
    font-size: 12px !important;
}
.fs-10
{
    font-size: 10px !important;
}
.fs-14
{
    font-size: 14px !important;
}
.fs-16
{
    font-size: 16px !important;
}
.h-100 {
    height: 100vh !important;
}
.b-b {
    border-bottom: 1px solid #ddd !important;
}

[dir="ltr"]:root {

    .bl-primary {
        border-left: 5px solid var(--ion-color-primary) !important;
    }

    .bl-success {
        border-left: 5px solid var(--ion-color-success) !important;
    }
    .bl-warning {
        border-left: 5px solid var(--ion-color-warning) !important;
    }
    .bl-danger {
        border-left: 5px solid var(--ion-color-danger) !important;
    }
}

[dir="rtl"]:root {

    .bl-primary {
        border-right: 5px solid var(--ion-color-primary) !important;
    }
    .bl-success {
        border-right: 5px solid var(--ion-color-success) !important;
    }
    .bl-warning {
        border-right: 5px solid var(--ion-color-warning) !important;
    }
    .bl-danger {
        border-right: 5px solid var(--ion-color-danger) !important;
    }

}

.bg-light, .bg-light * {
    --background: var(--ion-color-light) !important;
    background: var(--ion-color-light) !important;
}
.bg-darkgray, .bg-darkgray * {
    --background: var(--ion-color-dark) !important;
}
.bg-primary, .bg-primary * {
    --background:  var(--ion-color-primary) !important;
    background:  var(--ion-color-primary) !important;
}
.bg-success, .bg-success * {
    --background: var(--ion-color-success) !important;
    background: var(--ion-color-success) !important;
}
.bg-warning, .bg-warning * {
    --background: var(--ion-color-warning) !important;
}
.bg-danger, .bg-danger * {
    background: var(--ion-color-danger) !important;
    --background: var(--ion-color-danger) !important;
}
.bg-white, .bg-white * {
    --background: #fff !important;
    background: #fff !important;
}
.bg-danger-overlay, .bg-danger-overlay * {
    --background: #fce8e6 !important;
    color: #d93025;
}
.bg-primary-overlay, .bg-primary-overlay * {
    --background: #e8f0fe !important;
    color: #000;
}
.bg-success-overlay, .bg-success-overlay * {
    --background: #e8f0f0 !important;
    color: #000;
}
.bg-warning-overlay, .bg-warning-overlay * {
    --background: #eade8f !important;
    color: #000;
}
.bg-light-overlay, .bg-light-overlay * {
    --background: #eee !important;
    color: #000;
}
.text-muted {
    color: #aaa !important;
}
.text-success, .text-success * {
    color: var(--ion-color-success) !important;
    --color: var(--ion-color-success) !important;
}
.text-danger, .text-danger * {
    color: var(--ion-color-danger) !important;
    --color: var(--ion-color-danger) !important;
}
.text-primary, .text-primary * {
    color: var(--ion-color-primary) !important;
    --color: var(--ion-color-primary) !important;
}
.text-warning, .text-warning * {
    color:  var(--ion-color-warning) !important;
    --color:  var(--ion-color-warning) !important;
}
.text-dark , .text-dark *{
    color: rgb(98, 98, 98) !important;//var(--ion-color-dark)
}
.text-white, .text-white * {
    color: var(--ion-color-white) !important;
    --color: var(--ion-color-white) !important;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    //-webkit-overflow-scrolling: touch;
}

/** ::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none !important;
}*/

nz-avatar {
    padding: 13px 10px !important;
    border-radius: 100% !important;
    font-weight: 600;
}

[dir="ltr"]:root {

    nz-avatar {
        margin-right: 15px;
        margin-left: -5px;
    }

    ion-back-button {
        margin-left: -5px;
    }
}

[dir="rtl"]:root {
    
    ion-back-button {
        margin-right: -5px;
    }
    
    nz-avatar {
        margin-left: 15px;
        margin-right: -5px;
    }
}

.mt-05 {
    margin-top: 5px;
}
.mt-10 {
    margin-top: 10px !important;
}
.subtoolbar {
    margin-top: -10px !important;
}
.ion-border {
    border: 0.5px solid #e0e0e0 !important;
}
